/* eslint-disable camelcase,no-console,no-undef */
import React from 'react'
import _ from 'lodash';
import { connect } from 'react-redux';
import PropTypes from "prop-types";

import { isValidEmail } from '../../utils/methods';

function constantTimeCompare(val1, val2) {
  const str1 = String(val1);
  const str2 = String(val2);

  if (str1.length !== str2.length) {
    return false;
  }

  let result = 0;
  for (let i = 0; i < str1.length; i++) {
    result |= str1.charCodeAt(i) ^ str2.charCodeAt(i);
  }

  return result === 0;
}

const validations = {
  email: (value) => {
    if (_.isUndefined(value) || _.isNull(value)) {
      return 'Email is required'
    } else if (!isValidEmail(value.trim())) {
      return 'Invalid Email'
    }
    return null
  },
  emailOrUsername: (value) => {
    if (!(value.trim())) {
      return 'Email or User Name is required'
    }
    return null
  },
  password: (value) => {
    const regex = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})');
    if (!regex.test(value)) {
      return 'Must be at least 8 characters and contain one uppercase, lowercase, and number'
    }
    return null
  },
  loginPassword: (value) => {
    if (!(value.trim())) {
      return 'Password is required'
    }
    else if(value.trim().length < 8) {
      return 'Must be at least 8 characters'
    }
    return null
  },
  companyName: (value) => {
    if (!(value.trim())) {
      return 'Company Name is required'
    }
    return null
  },
  companyPassword: (value) => {
    if (value.trim().length < 8) {
      return 'Must be at least 8 characters'
    }
    return null
  },
  firstName: (value) => {
    if (!(value.trim())) {
      return 'First Name is required'
    }
    return null
  },
  lastName: (value) => {
    if (!(value.trim())) {
      return 'Last Name is required'
    }
    return null
  },
  confirmPassword: (value, password) => {
    // if(_.isUndefined(value) || _.isNull(value)) {
    if(value == null){
      return 'Confirm Password field required'
    } else if (!constantTimeCompare(value, password) /* value !== password */) {
      return 'Password and confirm password should match'
    }
    return null
  },
  confirmEmail: (value, email) => {
    if(_.isUndefined(value) || _.isNull(value)) {
      return 'Confirm Email field is required'
    } else if (value !== email) {
      return 'Email and confirm Email should match'
    }
    return null
  },
  phone: (value) => {
    if (!value.trim()) {
      return 'Phone number is required'
    } else if(value.length < 10) {
      return 'Invalid phone number'
    }
    return null
  },
  title: (value) => {
    if (!(value.trim())) {
      return 'Title is required'
    }
    return null
  },
  industries: (value) => {
    if (!(value.trim())) {
      return 'Industries required'
    }
    return null
  },
  size: (value) => {
    if (!(value.trim())) {
      return 'Size is required'
    }
    return null
  },
  message: (value) => {
    if (!(value.trim())) {
      return 'Message is required'
    }
    return null
  },

  city: (value) => {
    if (!(value.trim())) {
      return 'city is required'
    }
    return null
  },

  height: (value) => {
    if (!(value.trim())) {
      return 'height is required'
    }
    return null
  }
};

const withValidations = (Component) => {
  class FormComponent extends React.Component {
    state = {
      touched: {},
      errors: {}
    };

    componentDidMount = () => {
      const { userData, history} = this.props;
      const { pathname } = history.location;
      if(userData) {
        let obj = {};
        if(userData.firstName) {
          obj.firstName = userData.firstName;
        }
        if(userData.lastName) {
          obj.lastName = userData.lastName;
        }
        if(userData.password) {
          obj.password = userData.password;
          obj.confirmPassword = userData.password;
          const { errors } = this.state;
          errors.confirmPassword = validations.confirmPassword( obj.password, obj.confirmPassword);
          this.setState({
            errors
          });
        }
        if(userData.email || userData.confirmEmail) {
          obj.email = userData.email;
          obj.confirmEmail = userData.confirmEmail;
          const { errors } = this.state;
          errors.email = validations.email(obj.email);
          errors.confirmEmail = validations.confirmEmail(obj.confirmEmail, obj.email);
          this.setState({errors});
        }
        if (pathname == '/user') {
          this.setState({
            touched: {},
            errors: {}
          })
        } else{
          this.setState({
            touched: obj
          })
        }
      }
    };

    onChange = (e, getMethod) => {
      const { name, value } = e.target;
      const { touched } = this.state;
      if (validations[name]) {
        touched[name] = value;
        this.setState({ touched });

        if(name === 'confirmEmail' || (name === 'email' && touched.confirmEmail)) {
          this.validateConfirmEmail(name, value);
        } else if(name === 'confirmPassword' || (name === 'password' && touched.confirmPassword)) {
          this.validateConfirmPassword(name, value);
        } else {
          this.validate(touched);
        }
      }
      if(getMethod) {
        getMethod(e);
      }
    };

    validateConfirmPassword = (name, value) => {
      const { touched, errors } = this.state;
      if(name === 'confirmPassword' && touched.password) {
        errors.confirmPassword = validations.confirmPassword(value, touched.password);
        this.setState({
          errors
        });
      } else if(name === 'password' && touched.confirmPassword) {
        errors.confirmPassword = validations.confirmPassword(value, touched.confirmPassword);
        errors.password = validations.password(value);
        this.setState({
          errors
        });
      }
    };


    validateConfirmEmail = (name, value) => {
      const { touched, errors } = this.state;
      if(name === 'confirmEmail' && touched.email) {
        errors.confirmEmail = validations.confirmEmail(value, touched.email);
        this.setState({
          errors
        });
      } else if(name === 'email' && touched.confirmEmail) {
        errors.confirmEmail = validations.confirmEmail(value, touched.confirmEmail);
        errors.email = validations.email(value);
        this.setState({
          errors
        });
      }
    };


    validate = (props) => {
      const errors = this.state.errors;
      for (let key in validations) {
        if(key === 'confirmPassword' || key === 'confirmEmail') continue;
        const touched = this.state.touched[key];

        if (touched !== '') {
          if (!touched) continue;
        }

        const validator = validations[key];
        const value = props[key];
        errors[key] = validator(value)
      }
      this.setState({ errors })
    };

    render () {
      return (
        <Component
          {...this.props}
          {...this.state}
          onChange={(e, getMethod) => this.onChange(e, getMethod)}
        />
      )
    }
  }

  FormComponent.propTypes = {
    userData: PropTypes.object,
    history: PropTypes.object
  };
  
  const mapStateToProps = (state) => ({
    userData: state.register.userData
  });

  return connect(mapStateToProps, null)(FormComponent)
};

export default withValidations;