/* eslint-disable no-console,no-undef */
import React from "react";
import styled from "styled-components";
import LazyImage from "../common/LazyImage/LazyImage";
import { ImageUrl } from "../../utils/constants";
//const MediumFont = 'Rubik-Regular';
const BoldFont = "Rubik-Medium";
const RegularFont = "Rubik-Light";

const HeroContainer = styled.div`
  position: relative;
  background-color: #f4fbff;
  background-image: linear-gradient(rgb(230, 246, 255), rgb(207, 237, 255));
  width: 100%;
  padding: 65px 50px;
  float: left @media (min-width: 320px) and (max-width: 480px) {
    padding: 20px;
  }
  @media (max-width: 1000px) {
    padding: 60px 6vw;
  }
  @media (max-width: 736px) {
    padding: 50px 3vw;
  }
  @media (max-width: 500px) {
    padding: 50px 10px;
  }
  @media (min-width: 1600px) {
    padding-left: calc(calc(100% - 1500px) / 2);
    padding-right: calc(calc(100% - 1500px) / 2);
  }
`;
const HeroTitleContainer = styled.div`
  margin: 0 auto;
  padding: 20px;
  text-align: center;

  & > p {
    font-size: 18px;
    color: #7cbe42;
    font-family: ${BoldFont};
    text-transform: uppercase;
  }

  @media (max-width: 500px) {
    padding: 0;
  }
`;

const HeroSubTitle = styled.div`
  > h1 {
    font-size: 35px;
    color: #2b281e;
    font-family: ${BoldFont};
    font-weight: 500;
    text-align: center;
    max-width: 817px;
    margin: 0 auto;
  }
  & > p {
    font-family: ${RegularFont};
    font-size: 20px;
    color: #2b281e;
    font-weight: 400;
    text-align: center;
    margin-top: 15px;
  }
`;

const HeroSectionImageContainer = styled.div`
  padding: 10px;
  max-width: 400px;
  height: auto;
  margin: 0 auto;

  div{
    & > img {
    max-width: 100%;
    max-height: 100%;
  }

  }

  & > img {
    max-width: 100%;
    max-height: 100%;
  }
`;

const Top = () => (
  <HeroContainer>
    <HeroTitleContainer>
      <p />
      <HeroSubTitle>
        <h1>
          Cultivate Self-Propelled, Motivated Employees With Zero Headache
        </h1>
        <p>Your all-in-one wellbeing, recognition and engagement HUB</p>
      </HeroSubTitle>
    </HeroTitleContainer>
    <HeroSectionImageContainer>
      <LazyImage
        src={ImageUrl + "/images/HowItWorksV2/Hero-Section-Image.png"}
        alt="hero-section"
      />
    </HeroSectionImageContainer>
  </HeroContainer>
);

export default Top;
