/* eslint-disable no-console,no-undef, camelcase */
import React from 'react';
import PropTypes from 'prop-types';

import { StyledHeader, Container, StyledModalNew, CrossButton } from '../AddInputGoals/styles';
import { DemoMarginNew } from '../HowItWorksDashBoard/styles';

import VideoMaker from '../VideoMaker';
import { withTranslation } from 'react-i18next';
 
class DemoPopup extends React.Component {

  render() {
    const { showModal, videoUrl, VideoTitle, updateUserPoints } = this.props;
    const video = {
      video_url: videoUrl || 'https://vimeo.com/290188316'   
    }
    return(
      <StyledModalNew bsSize="large" /*className="largeModal"*/ show={showModal} style={{padding:"25px"}}>
        <CrossButton onClick={this.props.onClose}>
          <img src="/public/images/NewDashboardV2/closedropdown.png" alt="back-arrow" />
        </CrossButton>  
        <StyledHeader style={{display:"block", padding:"25px"}} >
          <Container>
            <div className="headerChallenge">
              {VideoTitle ? this.props.t(VideoTitle) : videoUrl?'': 'Woliba Demo'}
            </div>
          </Container>
          <DemoMarginNew height={videoUrl && '410px'}>
            <VideoMaker video={video} height="100%" updateUserPoints={updateUserPoints}/>
          </DemoMarginNew>
        </StyledHeader>
      </StyledModalNew>
    );
  }
}

DemoPopup.propTypes = {
  showModal: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  videoUrl: PropTypes.string,
  VideoTitle: PropTypes.string,
  updateUserPoints: PropTypes.func,
  t: PropTypes.func
}

export default ((withTranslation())(DemoPopup));